<template>
  <div id="main">
    <el-carousel
      :height="innerHeight + 'px'"
      direction="vertical"
      :autoplay="false"
      ref="carousel"
    >
      <el-carousel-item :key="0">
        <el-row class="imgBox">
          <el-col
            :xs="0"
            :sm="24"
            :md="24"
            :lg="24"
            class="imgbox1"
            v-if="bannerData.type == 2"
          >
            <video
              class="imgbox1"
              src="../assets/333.mp4"
              autoplay="autoplay"
              muted
              loop
            ></video>
          </el-col>
          <el-col
            :xs="0"
            :sm="24"
            :md="24"
            :lg="24"
            class="imgbox1"
            v-if="bannerData.type == 1"
          >
            <el-image class="imgbox2" :src="bannerData.bannerUrl" fit="cover" />
          </el-col>
          <!--          <div class="bannerTitle a-fadein">黑蜻蜓充电持续点亮您的生活</div>
          <div class="bannerDetail a-fadein">蜻蜓在线，手机有电</div>-->
        </el-row>
      </el-carousel-item>
      <el-carousel-item :key="1" class="cpfl">
        <div class="cpTitle" v-animation:home="'a-fadeinT'">产品分类</div>
        <el-row class="cpBox">
          <div class="cpinbox">
            <div
              class="cpMain"
              v-for="(item, index) in productList"
              :key="index"
            >
              <div class="imgbox">
                <el-image class="cpImage" :src="item.coverImgUrl" fit="cover">
                </el-image>
              </div>
              <div class="cpZT">
                <div class="cpBT">{{ item.name }}</div>
                <div class="cpMs">{{ item.introduction }}</div>
              </div>
            </div>
          </div>
        </el-row>
        <div class="centerbox">
          <div class="cpbtn hvr-bounce-to-right-1" @click="routers('/product')">
            更多
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item :key="2" class="ly">
        <div class="lyTitle" v-animation:home="'a-fadeinT'">
          选择黑蜻蜓共享充电的理由
        </div>
        <div class="lyLy" v-animation:home="'a-fadeinT'">
          团队优势介绍：公司拥有完善的运营团队、营销团队、设计团队以及管理团队，为您售前、售中、售后提供最完善理念，旨在让客户更安心、更放心、更省心！
        </div>
        <!-- <div class="lyImage">
          <el-image
            class="lyImage1"
            :src="require('../assets/ly.png')"
            fit="contain"
          />
        </div> -->
        <div class="liyou1">
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index01.png')"
              fit="contain"
            ></el-image>
            <div class="desc">自主研发体系</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index02.png')"
              fit="contain"
            ></el-image>
            <div class="desc">多项专利认证</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index03.png')"
              fit="contain"
            ></el-image>
            <div class="desc">24h在线客服服务</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index04.png')"
              fit="contain"
            ></el-image>
            <div class="desc">3个月本金保障</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index05.png')"
              fit="contain"
            ></el-image>
            <div class="desc">提现免手续费快速到账</div>
          </div>
        </div>
        <div class="liyou1 liyou2">
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index06.png')"
              fit="contain"
            ></el-image>
            <div class="desc">完备的培训体系</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index07.png')"
              fit="contain"
            ></el-image>
            <div class="desc">专属的多对一售后服务</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index08.png')"
              fit="contain"
            ></el-image>
            <div>全新智能分成系统</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index09.png')"
              fit="contain"
            ></el-image>
            <div>智能后台操作清晰透明</div>
          </div>
          <div class="item">
            <el-image
              class="img1"
              :src="require('../assets/index10.png')"
              fit="contain"
            ></el-image>
            <div class="desc">享受优惠扶持政策</div>
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item :key="3" class="cgal">
        <div class="cgalTitle" v-animation:home="'a-fadeinT'">成功案例</div>
        <el-row class="cgalBox">
          <div class="cgalinbox">
            <div
              class="cgalMain"
              v-for="(item, index) in successfulCasesList"
              :key="index"
            >
              <div class="imgbox">
                <el-image class="cgalImage" :src="item.imgUrl" fit="cover">
                </el-image>
              </div>

              <div class="cgalZT">
                <div class="cgalMs">{{ item.caseName }}</div>
              </div>
            </div>
          </div>
        </el-row>
        <div class="centerbox">
          <div
            class="cgalbtn hvr-bounce-to-right-1"
            @click="routers('/success')"
          >
            更多
          </div>
        </div>
      </el-carousel-item> -->
      <!-- <el-carousel-item :key="4" class="xwzx">
        <div class="xwzxTitle" v-animation:home="'a-fadeinT'">新闻资讯</div>
        <el-row class="xwzxBox">
          <div class="xwzxinbox">
            <div
              class="xwzxMain"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <div class="xwzxImg">
                <el-image
                  class="xwzxImg1"
                  :src="item.coverImgUrl"
                  fit="contain"
                />
              </div>
              <div class="xwzxNr">
                <div class="xwzxTitle1">
                  {{ item.title }}
                </div>
                <div class="xwzxtime">{{ item.createTime }}</div>
                <div class="xwzxMs" v-html="item.detail"></div>
              </div>
            </div>
          </div>
        </el-row>
        <div class="centerbox">
          <div class="xwzxbtn hvr-bounce-to-right-1" @click="routers('/news')">
            更多
          </div>
        </div>
      </el-carousel-item> -->
      <el-carousel-item :key="3">
        <HelloWorld></HelloWorld>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld";
import { banner, product, successfulCases, news } from "@/api/api_list";
export default {
  data() {
    return {
      innerHeight: "",
      index: 0,
      timer: "", //定时器
      canchange: true,
      mobieX: "",
      bannerData: {},
      productList: [],
      successfulCasesList: [],
      newsList: [],
    };
  },
  components: {
    HelloWorld,
  },
  created() {
    banner({ location: 1 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
    product({ pageNum: 1, pageSize: 4 }).then((res) => {
      if (res.code == 200) {
        var xx = res.data.list;
        this.productList = xx;
      }
    });
    successfulCases({ pageNum: 1, pageSize: 4 }).then((res) => {
      if (res.code == 200) {
        this.successfulCasesList = res.data.list;
      }
    });
    news({ pageNum: 1, pageSize: 4 }).then((res) => {
      if (res.code == 200) {
        this.newsList = res.data.list;
      }
    });
  },
  mounted() {
    // console.log(window.innerHeight);
    this.innerHeight = window.innerHeight;
    window.onresize = () => {
      this.innerHeight = window.innerHeight;
    };
    window.addEventListener("wheel", this.handleScroll, true);
    // console.log(document.getElementById("video"));
    document
      .getElementById("main")
      .addEventListener("touchstart", this.handleScrollmobile, true);
    document
      .getElementById("main")
      .addEventListener("touchmove", this.handleScrollmobile3, true);
    document
      .getElementById("main")
      .addEventListener("touchcancel", this.handleScrollmobile2, true);
    document
      .getElementById("main")
      .addEventListener("touchend", this.handleScrollmobile2, true);
  },
  methods: {
    routers(path) {
      this.$router.push(path);
    },
    //滚轮切换轮播图事件
    handleScroll(e) {
      if (this.canchange && e.deltaY > 0) {
        this.canchange = false;
        this.timer = setTimeout(() => {
          this.canchange = true;
        }, 500);
        let addindex = ++this.index;
        // console.log(addindex);
        if (addindex > 3) {
          this.index = 3;
        } else {
          this.$nextTick(() => {
            this.$refs.carousel.setActiveItem(addindex);
          });
          // setTimeout(() => {
          //   this.$refs.carousel.setActiveItem(addindex);
          // }, 1000);
        }
      }
      if (this.canchange && e.deltaY < 0) {
        this.canchange = false;
        this.timer = setTimeout(() => {
          this.canchange = true;
        }, 500);
        let addindex = --this.index;
        if (addindex < 0) {
          this.index = 0;
        } else {
          this.$nextTick(() => {
            this.$refs.carousel.setActiveItem(addindex);
          });
        }
      }
      // console.log(e, "Jinlail");
    },
    //移动端点击开始
    handleScrollmobile(e) {
      e.stopPropagation();
      // alert('开始了')
      console.log(e.touches[0].pageY, "开始位置");
      this.mobieX = e.touches[0].pageY;
    },
    //移动端点击开始
    handleScrollmobile3(e) {
      e.stopPropagation();
      console.log(e.touches[0].pageY, "移动位置");
      // this.mobieX=e.touches[0].pageY
    },
    //移动端手指结束
    handleScrollmobile2(e) {
      e.preventDefault();
      // alert('结束了')
      console.log(e.changedTouches[0].pageY, "结束位置");
      let torb = e.changedTouches[0].pageY - this.mobieX;
      console.log(torb);
      if (this.canchange && torb < 0) {
        this.canchange = false;
        this.timer = setTimeout(() => {
          this.canchange = true;
        }, 500);
        let addindex = ++this.index;
        if (addindex > 3) {
          this.index = 3;
        } else {
          this.$nextTick(() => {
            this.$refs.carousel.setActiveItem(addindex);
          });
        }
      }
      if (this.canchange && torb > 0) {
        this.canchange = false;
        this.timer = setTimeout(() => {
          this.canchange = true;
        }, 500);
        let addindex = --this.index;
        if (addindex < 0) {
          this.index = 0;
        } else {
          this.$nextTick(() => {
            this.$refs.carousel.setActiveItem(addindex);
          });
        }
      }
      console.log(this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/common.css";
/* Bounce To Right */
#main {
  background: #fff;
}
.centerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hvr-bounce-to-right-1 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  overflow: hidden;
}
.hvr-bounce-to-right-1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fdd947;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: hidden;
}
.hvr-bounce-to-right-1:hover,
.hvr-bounce-to-right-1:focus,
.hvr-bounce-to-right-1:active {
  color: rgb(231, 0, 0);
  border-color: #fdd947;
  overflow: hidden;
}
.hvr-bounce-to-right-1:hover:before,
.hvr-bounce-to-right-1:focus:before,
.hvr-bounce-to-right-1:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  overflow: hidden;
}
.nav-top {
  width: 100%;
  position: fixed;
  height: 0.8rem;
  // background: rgba(0, 0, 0, 0); 张
  background: red;
  z-index: 100000;
  .nav {
    height: 0.8rem;
  }
  .mainNav {
    .navBox {
      height: 0.8rem;
    }
    .navtitle {
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.16rem;
      // color: #fff;张
      color: #000000;
      font-weight: normal;
    }
  }
}
.imgBox {
  .imgbox1 {
    width: 100%;
    /* height: 100vh; */
    position: absolute;
    object-fit: fill;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
  .imgbox2 {
    width: 100%;
    height: 100%;
  }
  .bannerTitle {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;
    // color: #fff;
    color: #000000;
    top: 200px;
    font-weight: 900;
  }
  .bannerDetail {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    width: 100%;
    height: 40px;
    color: hsla(0, 0%, 100%, 0.4);
    text-align: center;
    font-size: 24px;
    top: 250px;
  }
}
.cpfl {
  width: 100%;
  height: 100%;
  .cpTitle {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;
    color: black;
    margin-top: 100px;
    font-weight: 900;
  }
  .cpBox {
    width: 80%;
    height: 50%;
    text-align: center;
    font-size: 28px;
    color: #fff;
    font-weight: 900;
    margin: 0 auto;
    margin-top: 20px;
    .cpinbox {
      height: 100%;
      /* background: red; */
      overflow-x: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .cpMain {
        /* display: inline-block; */
        width: 20%;
        height: 100%;
        background: white;
        .imgbox {
          width: 100%;
          height: 75%;
          /* background: aquamarine; */
          overflow: hidden;
          .cpImage {
            width: 100%;
            height: 100%;
            /* background: aquamarine; */
            transition: all 0.6s;
          }
          .cpImage:hover {
            transform: scale(1.1);
          }
        }
        .cpImage {
          width: 100%;
          height: 100%;
        }
        .cpBT {
          width: 100%;
          padding-top: 2%;
          height: 10%;
          line-height: 20px;
          text-align: center;
          color: black;
          font-size: 14px;
        }
        .cpMs {
          width: 90%;
          margin: 0 auto;
          /* line-height: 10px; */
          text-align: left;
          color: #606060;
          font-size: 11px;
          margin-top: 6px;
          /* padding: 5px; */
          box-sizing: border-box;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .cpbtn {
    width: 200px;
    height: 40px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    color: black;
    border: 1px solid black;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .cpTitle {
      margin-top: 28px;
    }
    .cpBox {
      margin-top: 60px;
      height: 50%;
      .cpinbox {
        display: flex;
        flex-direction: column;
        height: 100%;
        .cpMain {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          height: 24%;
          .imgbox {
            width: 40%;
            height: 100%;
            /* background: aquamarine; */
            overflow: hidden;
            .cpImage {
              width: 100%;
              height: 100%;
            }
          }
          .cpZT {
            width: 60%;
            height: 100%;
            .cpBT {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 40%;
              font-size: 16px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行
            }
            .cpMs {
              width: 100%;
              height: 60%;
              font-size: 12px;
              margin-top: 0;
            }
          }
        }
      }
    }
    /* .cpBox {
      margin-top: 80px;
      height: 60%;
      .cpinbox {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        .cpMain {
          width: 49%;
          height: 50%;
        }
      }
    } */
    .cpbtn {
      margin-top: 50px;
    }
  }
}
.ly {
  width: 100%;
  height: 100%;
  .lyTitle {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;
    color: black;
    margin-top: 100px;
    font-weight: 900;
  }
  .lyLy {
    width: 100%;
    height: 40px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    padding: 10px 10px;
  }
  .lyImage {
    margin: 0 auto;
    /* margin-top: 30px; */
    width: 90%;
    height: 60%;
    // background: red;
    .lyImage1 {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    .lyTitle {
      margin-top: 30%;
    }
    .lyImage {
      margin-top: -10px;
      width: 100%;
    }
  }
}
.cgal {
  width: 100%;
  height: 100%;
  .cgalTitle {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;
    color: black;
    margin-top: 100px;
    font-weight: 900;
  }
  .cgalBox {
    width: 80%;
    height: 50%;
    text-align: center;
    font-size: 28px;
    color: #fff;
    font-weight: 900;
    margin: 0 auto;
    margin-top: 20px;
    .cgalinbox {
      height: 100%;
      /* background: red; */
      overflow-x: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .cgalMain {
        width: 19%;
        height: 100%;
        background: #101010;
        .imgbox {
          width: 100%;
          height: 80%;
          /* background: aquamarine; */
          overflow: hidden;
          .cgalImage {
            width: 100%;
            height: 100%;
            /* background: aquamarine; */
            transition: all 0.6s;
          }
          .cgalImage:hover {
            transform: scale(1.1);
          }
        }
        .cgalZT {
          width: 100%;
          height: 20%;
          .cgalMs {
            margin: 0 auto;
            margin-top: 10px;
            width: 90%;
            /* height: 70%; */
            /* line-height: 20px; */
            text-align: left;
            color: #606060;
            font-size: 12px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            /* margin-top: 20px; */
          }
        }
      }
    }
  }
  .cgalbtn {
    width: 200px;
    height: 40px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    color: black;
    border: 1px solid black;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .cgalTitle {
      margin-top: 28px;
    }
    .cgalBox {
      margin-top: 80px;
      height: 50%;
      .cgalinbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .cgalMain {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          height: 20%;
          .imgbox {
            width: 30%;
            height: 100%;
            /* background: aquamarine; */
            overflow: hidden;
            .cgalImage {
              width: 100%;
              height: 100%;
            }
          }

          .cgalZT {
            width: 70%;
            height: 100%;
            .cgalBT {
              width: 100%;
              height: 40%;
              font-size: 12px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行
            }
            .cgalMs {
              width: 100%;
              height: 60%;
              font-size: 12px;
              margin-top: 0;
            }
          }
        }
      }
    }
    .cgalbtn {
      margin-top: 50px;
    }
  }
}
.xwzx {
  width: 100%;
  height: 100%;
  .xwzxTitle {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;
    color: #fff;
    margin-top: 100px;
    font-weight: 900;
  }
  .xwzxBox {
    width: 80%;
    height: 50%;
    text-align: center;
    font-size: 28px;
    color: #fff;
    font-weight: 900;
    margin: 0 auto;
    margin-top: 20px;
    .xwzxinbox {
      height: 100%;
      /* background: red; */
      overflow-x: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      .xwzxMain {
        width: 49%;
        height: 45%;
        background: #101010;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .xwzxImg {
          width: 30%;
          height: 80%;
          .xwzxImg1 {
            width: 100%;
            height: 100%;
            /* background: aquamarine; */
            transition: all 0.6s;
          }
          .xwzxImg1:hover {
            transform: scale(1.1);
          }
        }
        .xwzxNr {
          width: 60%;
          height: 80%;
          /* display: flex;
          flex-direction: column; */
          text-align: initial;
          .xwzxTitle1 {
            width: 100%;
            /* height: 40%; */
            display: flex;
            /* align-items: center; */
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .xwzxtime {
            width: 100%;
            height: 10%;
            font-size: 10px;
            padding-top: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          }
          .xwzxMs {
            width: 100%;
            /* height: 50%; */
            font-size: 11px;
            padding-top: 10px;
            box-sizing: border-box;
            color: hsla(0, 0%, 100%, 0.2);
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
  .xwzxbtn {
    width: 200px;
    height: 40px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #000000;
  background-color: white;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #000000;
  background-color: white;
}

.liyou1 {
  margin-top: 7vw;
  margin-left: 5vw;
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: white;
}
.liyou1 .item {
  width: 20%;
  font-size: 1.5vw;
  color: #000000;

  height: 6.5vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.liyou1 .img1 {
  // width: 10vw;
  width: 10vw;
  height: 3vw;
}

.liyou2 {
  margin-top: 7vw;
}
</style>
